import React from "react";
import {
  BiLogoTypescript,
  BiLogoCPlusPlus,
  BiLogoHtml5,
  BiLogoGit,
  BiLogoJavascript,
  BiLogoCss3,
  BiLogoTailwindCss,
  BiLogoRedux,
  BiLogoFirebase,
} from "react-icons/bi";
import { SiMui } from "react-icons/si";
import { FaCloudMeatball, FaReact } from "react-icons/fa6";
import { BsDatabaseFillGear } from "react-icons/bs";

const iconsMap = {
  "React.js": <FaReact />,
  TypeScript: <BiLogoTypescript />,
  "C++": <BiLogoCPlusPlus />,
  HTML5: <BiLogoHtml5 />,
  Git: <BiLogoGit />,
  JavaScript: <BiLogoJavascript />,
  "CSS/SCSS": <BiLogoCss3 />,
  TailwindCss: <BiLogoTailwindCss />,
  Redux: <BiLogoRedux />,
  "Material-UI": <SiMui />,
  "REST API": <FaCloudMeatball />,
  Firebase: <BiLogoFirebase />,
  SQL: <BsDatabaseFillGear />,
};

const Resume = ({ data }) => {
  if (data) {
    var skillmessage = data.skillmessage;
    var education = data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description2}</p>
        </div>
      );
    });
    var work = data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <pre>{work.description}</pre>
        </div>
      );
    });
    var project = data.project.map(function (project) {
      return (
        <div key={project.company}>
          <h3>{project.company}</h3>
          <p className="info">
            {project.title}
            <span>&bull;</span> <em className="date">{project.years}</em>
          </p>
          <p>{project.description}</p>
        </div>
      );
    });
    var skills = data.skills.map(function (skills) {
      return (
        <span key={skills.name}>
          <em>
            <span className="bar-icon">{iconsMap[skills.name]}</span>
            {skills.name}
          </em>
        </span>
      );
    });
  }

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Work</span>
          </h1>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div>

      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Project</span>
          </h1>
        </div>

        <div className="nine columns main-col">{project}</div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <div className="skills">{skills}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
